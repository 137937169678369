// Main exports of the Unify Browser global
export * as react_virtual from '@tanstack/react-virtual';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
export * from 'src';
export * from 'src/components.dart';
export { createMaterialSymbolsIcon } from 'src/components/MaterialSymbolsIcon/createMaterialSymbolsIcon';
export { default as buttonClasses } from 'src/wsd/components/Button/buttonClasses';
// These WSD wrapper components are exported mainly to maintain backwards compatibility with the JS bundle.
// Some of them are also referenced in the nested `react_material_ui` package which doesn't have Dart bindings generated.
export {
  DisabledAncestorProvider,
  useIsAncestorDisabled,
} from 'src/context/DisabledAncestorContext/DisabledAncestorContext';
// eslint-disable-next-line deprecation/deprecation
export { Label, type LabelProps } from 'src/wsd/components/Label/Label';
export {
  WsdAlert as Alert,
  type WsdAlertProps as AlertProps,
} from 'src/wsd/components/WsdAlert/WsdAlert';
export {
  WsdBadge as Badge,
  WsdBadge,
  type WsdBadgeProps as BadgeProps,
  type WsdBadgeProps,
} from 'src/wsd/components/WsdBadge/WsdBadge';
export {
  WsdLinearProgress as LinearProgress,
  WsdLinearProgress,
  type WsdLinearProgressProps as LinearProgressProps,
  type WsdLinearProgressProps,
} from 'src/wsd/components/WsdLinearProgress/WsdLinearProgress';
export {
  WsdLinkButton as LinkButton,
  type WsdLinkButtonProps as LinkButtonProps,
} from 'src/wsd/components/WsdLinkButton/WsdLinkButton';

export * from 'src/utils/localized_dayjs';

/**
 * Loads the ReactMaterialUI DateRangePicker js bundle and its dependencies asynchronously.
 *
 * Calling this function is not required to render {@link DateRangePicker} since it is a
 * `React.lazy` component that will load the bundle right before the component is
 * rendered.
 *
 * This function allows consumers to optionally load the DateRangePicker
 * bundle earlier in the background before {@link DateRangePicker} is called. Doing this
 * might reduce the time the fallback UI in {@link React.Suspense} is rendered while
 * waiting for the bundle to be loaded before rendering {@link DateRangePicker}.
 */
export async function loadDatePickers(): Promise<void> {
  await import('./browser.date_pickers');
}

/**
 * Loads the ReactMaterialUI DataGrid js bundle and its dependencies asynchronously.
 *
 * Calling this function is not required to render {@link DataGridPro} since it is a
 * `React.lazy` component that will load the bundle right before the component is
 * rendered.
 *
 * This function allows consumers to optionally load the DataGrid
 * bundle earlier in the background before {@link DataGridPro} is called. Doing this
 * might reduce the time the fallback UI in {@link React.Suspense} is rendered while
 * waiting for the bundle to be loaded before rendering {@link DataGridPro}.
 */
export async function loadDataGrid(): Promise<void> {
  await import('./browser.data_grid_v6');
}

/**
 * Loads the ReactMaterialUI DataGridPro js bundle and its dependencies asynchronously.
 *
 * Calling this function is not required to render {@link DataGridPro} since it is a
 * `React.lazy` component that will load the bundle right before the component is
 * rendered.
 *
 * This function allows consumers to optionally load the DataGridPro
 * bundle earlier in the background before {@link DataGridPro} is called. Doing this
 * might reduce the time the fallback UI in {@link React.Suspense} is rendered while
 * waiting for the bundle to be loaded before rendering {@link DataGridPro}.
 */
export async function loadDataGridPro(): Promise<void> {
  await import('./browser.data_grid_pro_v7');
}

/**
 * Loads the ReactMaterialUI DataGridPremium js bundle and its dependencies asynchronously.
 *
 * Calling this function is not required to render {@link DataGridPremium} since it is a
 * `React.lazy` component that will load the bundle right before the component is
 * rendered.
 *
 * This function allows consumers to optionally load the DataGridPremium
 * bundle earlier in the background before {@link DataGridPremium} is called. Doing this
 * might reduce the time the fallback UI in {@link React.Suspense} is rendered while
 * waiting for the bundle to be loaded before rendering {@link DataGridPremium}.
 */
export async function loadDataGridPremium(): Promise<void> {
  await import('./browser.data_grid_premium');
}

// We don't want to generate this, so export it from this file instead of from components.dart.ts
export { DataGridProLazy as DataGridPro } from 'src/components/DataGridPro/DataGridProLazyV6';

import { generateUtilityClasses } from '@mui/material';
import generateUtilityClass from '@mui/utils/generateUtilityClass';

export type AssistantFabClasses = {
  root: string;
};

export function getAssistantFabUtilityClass(slot: string): string {
  return generateUtilityClass('AssistantFab', slot);
}

const assistantFabClasses = generateUtilityClasses('AssistantFab', ['root']);

export default assistantFabClasses;

import * as React from 'react';
import type { DateRangeCalendar as MuiDateRangeCalendar } from 'src/date_pickers';

/**
 * DateRangeCalendar lets the user select a range of dates without any input or popper / modal.
 *
 * > ⚠️ *Warning*: This is a React.lazy component that must be wrapped with `React.Suspense`.
 */
export const DateRangeCalendarLazy = React.lazy<typeof MuiDateRangeCalendar>(
  () =>
    import('src/browser.date_pickers').then((module) => ({
      default: module.DateRangeCalendar,
    }))
);

import { Divider, useThemeProps } from '@mui/material';
import {
  default as MuiDialogTitle,
  DialogTitleTypeMap as MuiDialogTitleTypeMap,
} from '@mui/material/DialogTitle';
import { OverrideProps } from '@mui/material/OverridableComponent';
import { visuallyHidden } from '@mui/utils';

import * as React from 'react';

import {
  ExtendTypeMap,
  forwardRefToOverridableComponent,
} from 'src/utils/forwardRefToOverridableComponent';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DialogTitleOwnProps {}

export type DialogTitleTypeMap<
  AdditionalProps = unknown,
  RootComponent extends React.ElementType = MuiDialogTitleTypeMap['defaultComponent']
> = ExtendTypeMap<
  MuiDialogTitleTypeMap,
  AdditionalProps & DialogTitleOwnProps,
  RootComponent
>;

export type DialogTitleProps<
  RootComponent extends React.ElementType = MuiDialogTitleTypeMap['defaultComponent'],
  AdditionalProps = unknown
> = OverrideProps<
  DialogTitleTypeMap<AdditionalProps, RootComponent>,
  RootComponent
>;

/**
 *
 * Demos:
 *
 * - [Dialog](https://mui.com/material-ui/react-dialog/)
 *
 * API:
 *
 * - [DialogTitle API](https://mui.com/material-ui/api/dialog-title/)
 */
export const DialogTitle = forwardRefToOverridableComponent<
  DialogTitleTypeMap,
  DialogTitleProps
>(function UnifyDialogTitle(inProps, forwardedRef) {
  const props = useThemeProps({
    props: inProps,
    name: 'MuiDialogTitle',
  });

  // For now, we're always rendering a visually hidden `<hr>` element below the
  // dialog heading element to prevent the top padding on the adjacent DialogContent
  // from collapsing due to CSS padding collapse behavior that the UI team does not
  // want.
  //
  // In a follow-up effort, this same Divider will conditionally be visible to the
  // user when a new `divider` prop is made available.
  return (
    <>
      <MuiDialogTitle {...props} ref={forwardedRef} />
      <Divider
        data-test-id="dialog-title-divider"
        aria-hidden={true}
        sx={visuallyHidden}
      />
    </>
  );
});

export default DialogTitle;

/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import {
  chipClasses,
  styled,
  touchRippleClasses,
  useThemeProps,
} from '@mui/material';
import {
  default as MuiChip,
  ChipTypeMap as MuiChipTypeMap,
} from '@mui/material/Chip';
import { OverrideProps } from '@mui/material/OverridableComponent';
import * as React from 'react';
import { useIsAncestorDisabled } from 'src/context/DisabledAncestorContext/DisabledAncestorContext';
import {
  ExtendTypeMap,
  forwardRefToOverridableComponent,
} from 'src/utils/forwardRefToOverridableComponent';
import { generateWsdBadgeStyles } from 'src/wsd/components/Chip/style/variants/badge';
import { generateWsdLabelStyles } from 'src/wsd/components/Chip/style/variants/label';

// Module augmentation for size
// See: <https://next.material-ui.com/customization/theme-components/#adding-new-component-variants>
declare module '@mui/material/Chip' {
  interface ChipPropsSizeOverrides {
    large: true;
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ChipOwnProps {}

export type ChipTypeMap<
  AdditionalProps = unknown,
  RootComponent extends React.ElementType = MuiChipTypeMap['defaultComponent']
> = ExtendTypeMap<
  MuiChipTypeMap,
  AdditionalProps & ChipOwnProps,
  RootComponent
>;

export type ChipProps<
  RootComponent extends React.ElementType = MuiChipTypeMap['defaultComponent'],
  AdditionalProps = unknown
> = OverrideProps<ChipTypeMap<AdditionalProps, RootComponent>, RootComponent>;

const WsdChipRoot = styled(MuiChip, {
  name: 'WsdChip',
  label: 'WsdChip',
  slot: 'Root',
})(function WsdChipRoot({ theme, color, variant }) {
  if (!theme.isLegacyWsdTheme) return;
  // Widen the type of `variant` to avoid warnings when type checking wsd options that don't
  // technically exist in the union, but could be passed through the Dart wrapper.
  // We can remove support for this once we stop supporting these options in Dart.
  const wsdVariant: unknown = variant;

  return {
    ...((wsdVariant === 'wsdBadge' ||
      wsdVariant === 'wsdLabel' ||
      wsdVariant === 'wsdLabelStatus') && {
      // Styles for WSD Badges & Labels
      fontFamily: 'inherit',
      display: 'inline-block',
      textAlign: 'center',
      lineHeight: 'inherit',
      whiteSpace: 'nowrap',
      fontWeight: 'bold',
      height: 'auto',
      [`.${chipClasses.label}`]: {
        paddingLeft: '0',
        paddingRight: '0',
      },
      [`.${touchRippleClasses.root}`]: {
        display: 'none',
      },
    }),
    ...(wsdVariant === 'wsdBadge' && generateWsdBadgeStyles(theme, color!)),
    ...(variant &&
      (wsdVariant === 'wsdLabel' || wsdVariant === 'wsdLabelStatus') &&
      generateWsdLabelStyles(theme, color!, variant)),
  };
});

// This wrapper will be removed when we move these styles into the wkTheme in FED-1259.
// The only thing that should remain is the module augmentation to add the addition size option.
export const Chip = forwardRefToOverridableComponent<ChipTypeMap, ChipProps>(
  function Chip(inProps, forwardedRef) {
    const props = useThemeProps({ props: inProps, name: 'MuiChip' });
    const {
      color = 'default',
      variant = 'filled',
      // Prevent props.children from being passed along to MUI Chip since it's not supported.
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      children,
      disabled: disabledProp,
      ...rest
    } = props;
    const ancestorDisabled = useIsAncestorDisabled();
    const disabled = disabledProp ?? ancestorDisabled ?? false;
    return (
      <WsdChipRoot
        disabled={disabled}
        color={color}
        variant={variant}
        ref={forwardedRef}
        {...rest}
      />
    );
  }
);

export default Chip;

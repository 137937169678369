// Entrypoint for all exports shared by the browser and node bundles.
/***********************************************************************************
 *      DO NOT PUT ANYTHING IN THIS FILE THAT CANNOT BE USED BY ALL BUNDLES!       *
 ***********************************************************************************
 * I'll make this easy to remember. Keep this station clear, or I WILL _KEAL_ YOU! *
 ***********************************************************************************/
import type {} from '@mui/lab/themeAugmentation'; // Necessary to use theme augmentation on lab components
import type {} from '@mui/x-data-grid-premium/themeAugmentation'; // Necessary to use theme augmentation on datagrid components
import type {} from '@mui/x-date-pickers-pro/themeAugmentation'; // Necessary to use theme augmentation on datepicker components
import type {} from 'mui-x-date-pickers-v6/themeAugmentation'; // Necessary to use theme augmentation on datepicker components
import './mui_pro_license';

import muiInfo from '@mui/material/package.json';
import { createFilterOptions } from '@mui/material/useAutocomplete';

import defaultTheme from 'src/styles/themes/default/defaultTheme';
import wkTheme from 'src/wsd/styles/theme/wkTheme';

// IMPORTANT TO KEEP THIS ALIAS `MuiList`, to match the legacy naming in RMUI - this can be removed when the RMUI Dart package is removed.
export {
  List as MuiList,
  styled,
  useColorScheme,
  useMediaQuery,
  useTheme,
  // IMPORTANT TO KEEP THIS ALIAS `MuiListProps`, for consistency with `MuiList`
  type ListProps as MuiListProps,
} from '@mui/material';
export type { GridSize, Theme } from '@mui/material';
export { buttonGroupClasses } from '@mui/material/ButtonGroup';
export { listItemClasses } from '@mui/material/ListItem';
export { typographyClasses } from '@mui/material/Typography';
export {
  alpha,
  darken,
  decomposeColor,
  emphasize,
  getContrastRatio,
  getLuminance,
  hexToRgb,
  hslToRgb,
  lighten,
  recomposeColor,
  rgbToHex,
} from '@mui/material/styles';
export { visuallyHidden } from '@mui/utils';
export * from 'src/components.common';
export { BreadcrumbModel } from 'src/components/BreadcrumbNav/BreadcrumbNav';
export { default as buttonToolbarClasses } from 'src/components/ButtonToolbar/buttonToolbarClasses';
export { DropdownButton } from 'src/components/DropdownButton/DropdownButton';
export type { default as OverlayActionApi } from 'src/components/OverlayTrigger/OverlayActionApi';
export {
  // eslint-disable-next-line deprecation/deprecation
  WorkivaMuiThemeProvider,
  type WorkivaMuiThemeProviderProps,
} from 'src/components/WorkivaMuiThemeProvider/WorkivaMuiThemeProvider';
export { default as createTheme } from 'src/styles/createTheme';
export { getThemePalette } from 'src/styles/utils';
export {
  configureGlobalUnifyLocale,
  localeSettings,
} from 'src/utils/configLocale';
export { default as RemValue } from 'src/utils/rem_value';
export { default as themeSpacingAsRem } from 'src/utils/theme_spacing_as_rem';
// eslint-disable-next-line deprecation/deprecation
export { createFilterOptions, defaultTheme, version, wkTheme };
const version = muiInfo.version;

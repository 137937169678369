import {
  TablePagination as MuiTablePagination,
  TablePaginationProps as MuiTablePaginationProps,
} from '@mui/material';
import * as React from 'react';

export type TablePaginationProps<T = unknown> = Omit<
  MuiTablePaginationProps,
  'onRowsPerPageChange'
> & {
  /**
   * The component used for the root node. Either a string to use an HTML element or a component.
   *
   * @default TableCell
   * */
  component?: React.ElementType;

  /**
   * Callback fired when the items per page select option changes.
   *
   * @param {ChangeEvent<T>} [event] The event source of the callback.
   *
   * **Warning**: This is a generic event not a change event unless the change event is caused by browser autofill.
   * If using Dart, attempting to access `event.target.value` will throw. You can get the selected value from the second parameter.
   * @param {any} [value] The selected value.
   * @param {object} [child] The react element that was selected.
   */
  onRowsPerPageChange?: (
    event: React.ChangeEvent<T> | Event,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    child: React.ReactElement
  ) => void;
};

/** OverReact bindings for the Material-UI `TablePagination` JS component.
 *
 * A `TableCell` based component for placing inside `TableFooter` for pagination.
 *
 * Demos:
 *
 * - [Tables](https://mui.com/material-ui/react-table/)
 *
 * API:
 *
 * - [TablePagination API](https://mui.com/material-ui/api/table-pagination/)
 * - inherits [TableCell API](https://mui.com/material-ui/api/table-cell/)
 */
export const TablePagination = React.forwardRef<
  HTMLDivElement,
  TablePaginationProps<HTMLInputElement | HTMLTextAreaElement>
>(function TablePagination(props, forwardedRef) {
  const { onRowsPerPageChange, getItemAriaLabel, ...rest } = props;
  let onRowsPerPageChangeCallback;
  let getItemAriaLabelCallback;
  if (onRowsPerPageChange) {
    onRowsPerPageChangeCallback = React.useCallback(
      (
        event:
          | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          | Event,
        ...rest
      ) => {
        let value;
        if (
          event &&
          'target' in event &&
          event.target &&
          'value' in event.target
        ) {
          value = event.target.value;
        }
        onRowsPerPageChange(event, value, rest[0]);
      },
      [onRowsPerPageChange]
    );
  }

  if (getItemAriaLabel) {
    getItemAriaLabelCallback = React.useCallback(
      (type) => getItemAriaLabel(type),
      [getItemAriaLabel]
    );
  }

  return (
    <MuiTablePagination
      ref={forwardedRef}
      onRowsPerPageChange={onRowsPerPageChangeCallback}
      getItemAriaLabel={getItemAriaLabelCallback}
      {...rest}
    />
  );
});

export default TablePagination;

import { generateUtilityClasses } from '@mui/material';
import generateUtilityClass from '@mui/utils/generateUtilityClass';

export type AssistantChatFabClasses = {
  root: string;
};

export function getAssistantChatFabUtilityClass(slot: string): string {
  return generateUtilityClass('AssistantChatFab', slot);
}

const assistantChatFabClasses = generateUtilityClasses('AssistantChatFab', [
  'root',
]);

export default assistantChatFabClasses;
